"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";
import React from "react";
import styles from "components/error-boundary/error-boundary.module.scss";
import Button from "components/controls/buttons";
export default function GlobalError({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const [eventId, setEventId] = useState(null);
  useEffect(() => {
    const eventId = Sentry.captureException(error);
    setEventId(eventId);
  }, [error]);
  const handleReportFeedback = () => {
    Sentry.showReportDialog({
      eventId: eventId
    });
  };
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
			<body>
				<div className={styles.errorBoundary}>
					<h3>
						Something went wrong.
						<br />
						<span className={styles.subHeading}>
							We&apos;re sorry, but this service is temporarily unavailable.
							Please check back soon.
						</span>
					</h3>
					{eventId && <p>Our team has been notified of this occurrence.</p>}
					<Button appearance="secondary" onClick={() => reset()} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
						Try again
					</Button>
					<a role="button" className={styles.feedbackButton} onClick={handleReportFeedback}>
						Report feedback
					</a>
				</div>
			</body>
		</html>;
}